import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import 'toastr/toastr.scss';
import './App.scss';
import Fallback from './Fallback';
import PrivateRoute from './PrivateRoute';
import SuccessfullSubmit from './common/SuccessfullSubmit/SuccessfullSubmit';
import { marketingSiteRegistrationPageUrl } from './constants/mlp-constants';
import Layout from './core/layout/Layout';
import EditClientAdminForm from './features/AccessManagement/EditClientAdminForm';
import InternalUser from './features/AccessManagement/EditInternalUserForm';
import NewClientAdminForm from './features/AccessManagement/NewClientAdminForm';
import UserSearchForm from './features/AccessManagement/UserSearchForm';
import Auth from './features/Auth/Auth';
import FeedbackForm from './features/Auth/FeedbackForm';
import HomeForm from './features/Auth/HomeForm';
import SignOut from './features/Auth/SignOut';
import TermsOfServiceForm from './features/Auth/TermsOfServiceForm';
import TermsOfServiceFrenchForm from './features/Auth/TermsOfServiceFrenchForm';
import BRPForm from './features/BRPReports/BRPReportsForm';
import EmailConfirmedForm from './features/ClientAdmin/FeatureSetup/EmailConfirmedForm';
import FeatureSetupForm from './features/ClientAdmin/FeatureSetup/FeatureSetupForm';
import IMSUserNotFoundForm from './features/ClientAdmin/FeatureSetup/IMSUserNotFoundForm';
import StepFinishedForm from './features/ClientAdmin/FeatureSetup/StepFinishedForm';
import OnBehalfOfForm from './features/ClientAdmin/OnBehalfOf/OnBehalfOfForm';
import VerifyAccessAdminForm from './features/ClientAdmin/VerifyAccess/VerifyAccessAdminForm';
import DashboardForm from './features/Dashboard/DashboardForm';
import DashboardFormCopy from './features/Dashboard/DashboardFormCopy';
import BasicError from './features/Error/BasicError';
import Error from './features/Error/Error';
import LivingstonForm from './features/Home/LivingstonForm';
import DownloadLobby from './features/Imaging/DownloadLobby/DownloadLobby';
import SearchResultDetails from './features/Imaging/SearchResultDetails/SearchResultDetails';
import IndexForm from './features/Imaging/ViewImages/IndexForm';
import ManageNoticesForm from './features/ManageNotices/ManageNoticesForm';
import NoticeForm from './features/Notices/NoticesForm';
import SearchShipmentForm from './features/SearchShipment/SearchShipmentForm';
import BatchCreateUsersForm from './features/SystemAdmin/BatchCreateUsers/BatchUserCreateRouter';
import BatchEditUsersForm from './features/SystemAdmin/BatchEditUsers/BatchUserEditRouter';
import SystemAdminIndexForm from './features/SystemAdmin/IndexForm';
import ManageUserForm from './features/SystemAdmin/ManageUser/ManageUserForm';
import SearchUserForm from './features/SystemAdmin/SearchUser/SearchUserForm';
import TeamManagementDetailsForm from './features/TeamManagement/TeamManagementDetailsForm';
import TeamManagementForm from './features/TeamManagement/TeamManagementForm';
import TestForm from './features/TestForm/TestForm';
import { PortalRoutes } from './portal/common/constants/constants-portal';
import Clearances from './portal/pages/clearances/Portal';
import CompleteEntryData from './portal/pages/completeEntryData/Portal';
import Contacts from './portal/pages/contacts/Portal';
import Documents from './portal/pages/documents/Portal';
import Faq from './portal/pages/faq/Portal';
import OverviewDashboard from './portal/pages/overviewDashboard/Portal';
import ProductReport from './portal/pages/productReport/Portal';
import Profile from './portal/pages/profile/Portal';
import Subscribers from './portal/pages/subscribers/Portal';
import Team from './portal/pages/team/Portal';
import TermsOfUse from './portal/pages/termsOfUse/Portal';

const WrapWithCredsAndRoutes = React.lazy(() => import('./WrapWithCredsAndRoutes'));

const App = () => {
    return (
        <div id='page' className='dashboard wide-page'>
            <React.Suspense fallback={<Fallback />}>
                <Router>
                    <Switch>
                        <Route path={['/Central', '//Central']} render={() => <Redirect to='/OverviewDashboard' />} />
                        <Route
                            path={[PortalRoutes.clearances, `/${PortalRoutes.clearances}`]}
                            render={() => <WrapWithCredsAndRoutes needTerms={true} childComponent={Clearances} />}
                        />
                        <Route
                            path={[PortalRoutes.dashboard, `/${PortalRoutes.dashboard}`]}
                            render={() => <WrapWithCredsAndRoutes needTerms={true} childComponent={OverviewDashboard} />}
                        />
                        <Route path={[PortalRoutes.team, `/${PortalRoutes.team}`]} component={Team} />
                        <Route path={[PortalRoutes.profile, `/${PortalRoutes.profile}`]} component={Profile} />
                        <Route path={[PortalRoutes.subscribers, `/${PortalRoutes.subscribers}`]} component={Subscribers} />
                        <Route path={[PortalRoutes.contacts, `/${PortalRoutes.contacts}`]} component={Contacts} />
                        <Route path={[PortalRoutes.documents, `/${PortalRoutes.documents}`]} component={Documents} />
                        <Route
                            path={[PortalRoutes.completeEntryData, `/${PortalRoutes.completeEntryData}`]}
                            component={CompleteEntryData}
                        />
                        <Route path={[PortalRoutes.termsOfUse, `/${PortalRoutes.termsOfUse}`]} component={TermsOfUse} />
                        <Route path={[PortalRoutes.faq, `/${PortalRoutes.faq}`]} component={Faq} />
                        <Route path={[PortalRoutes.productReport, `/${PortalRoutes.productReport}`]} component={ProductReport} />
                        <Route
                            exact
                            path={['/ClientAdmin', '/ClientAdmin/SignUp', '/ClientAdmin/SignUp/Index']}
                            component={() => {
                                window.location.replace(marketingSiteRegistrationPageUrl);
                                return null;
                            }}
                        />
                        <Layout>
                            <Switch>
                                <PrivateRoute path={['/Dashboard', '//Dashboard', '//', '/']} exact component={DashboardForm} />

                                <PrivateRoute path={['/DashboardCopy', '//DashboardCopy']} exact component={DashboardFormCopy} />

                                <PrivateRoute
                                    path={['/ClientAdmin/Signup/OnBehalfOf', '//ClientAdmin/Signup/OnBehalfOf']}
                                    component={OnBehalfOfForm}
                                />
                                <PrivateRoute
                                    path={['/ClientAdmin/Signup/VerifyAccess', '//ClientAdmin/Signup/VerifyAccess']}
                                    component={VerifyAccessAdminForm}
                                />
                                <PrivateRoute
                                    path={['/ClientAdmin/Signup/FeatureSetup', '//ClientAdmin/Signup/FeatureSetup']}
                                    component={FeatureSetupForm}
                                />
                                <Route
                                    path={['/ClientAdmin/Signup/EmailConfirmed', '//ClientAdmin/Signup/EmailConfirmed']}
                                    exact
                                    component={EmailConfirmedForm}
                                />
                                <PrivateRoute path='/ClientAdmin/Signup/StepFinished' exact component={StepFinishedForm} />
                                <PrivateRoute path='/ClientAdmin/Signup/IMSUserNotFound' exact component={IMSUserNotFoundForm} />

                                <PrivateRoute path='/Notices/Notices/ManageNotices' exact component={ManageNoticesForm} />
                                <PrivateRoute path='/Notices' exact component={NoticeForm} />

                                <PrivateRoute path='/TeamManagement' exact component={TeamManagementForm} />
                                <PrivateRoute
                                    path={[
                                        '/TeamManagement/TeamManagement/NewTeamMember',
                                        '/TeamManagement/TeamManagement/CloneTeamMember',
                                        '/TeamManagement/TeamManagement/TeamMemberDetails'
                                    ]}
                                    exact
                                    component={TeamManagementDetailsForm}
                                />

                                <PrivateRoute path='/Imaging' exact component={IndexForm} />
                                <PrivateRoute path='/Imaging/Imaging/SearchResultDetails' component={SearchResultDetails} />
                                <PrivateRoute path='/imaging/imaging/DownloadLobby' component={DownloadLobby} />

                                <PrivateRoute path={['/BRP', '//BRP', '/BRP/BRP']} exact component={BRPForm} />

                                <PrivateRoute path='/SystemAdmin' exact component={SystemAdminIndexForm} />
                                <PrivateRoute path='/SystemAdmin/BatchUser' exact component={BatchCreateUsersForm} />
                                <PrivateRoute path='/SystemAdmin/BatchUser/Edit' exact component={BatchEditUsersForm} />
                                <PrivateRoute path='/SystemAdmin/ManageUser' exact component={SearchUserForm} />
                                <PrivateRoute path='/SystemAdmin/ManageUser/ManageUser' exact component={ManageUserForm} />

                                <PrivateRoute path='/AccessManagement' exact component={EditClientAdminForm} />
                                <Route
                                    path='/AccessManagement/AccessManagement/SearchInternalUsers'
                                    exact
                                    render={(props) => <UserSearchForm {...props} isInternalUserEdit={true} />}
                                />
                                <PrivateRoute path='/AccessManagement/AccessManagement/SearchUsers' exact component={UserSearchForm} />
                                <PrivateRoute
                                    path='/AccessManagement/AccessManagement/NewClientAdmin'
                                    exact
                                    component={NewClientAdminForm}
                                />
                                <PrivateRoute path='/AccessManagement/AccessManagement/InternalUser/:userId?' component={InternalUser} />
                                <Route path='/Auth' exact component={Auth} />
                                <Route path='/Auth/SignOut' exact component={SignOut} />
                                <Route path='/Auth/TermsOfService' exact component={TermsOfServiceForm} />
                                <Route path='/Auth/TermsOfServiceFrench' exact component={TermsOfServiceFrenchForm} />
                                <PrivateRoute path={['/Home', '/Home/Home']} exact component={HomeForm} />
                                <PrivateRoute path='/Feedback' exact component={FeedbackForm} />

                                <PrivateRoute path='/SuccessfullSubmit' exact component={SuccessfullSubmit} />
                                <PrivateRoute path='/SearchShipment' exact component={SearchShipmentForm} />

                                <Route path='/TestForm' exact component={TestForm} />

                                <Route path={['/Home/Livingston', '//Home/Livingston']} exact component={LivingstonForm} />
                                <Route path='/Error' exact component={Error} />
                                <Route path='/BasicError' exact component={BasicError} />
                                <Route path='*' component={Error} />
                            </Switch>
                        </Layout>
                    </Switch>
                </Router>
            </React.Suspense>
        </div>
    );
};

export default App;
