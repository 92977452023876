import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ClientService } from '../models';

interface ClientsAndPermissionsTableCheckboxProps extends GridCellProps {
    isDisabled?: boolean;
    dataItem: ClientService;
    onSelect: (payload: { clientServiceIndex: number; featureName: string }) => void;
}

export default function ClientsAndPermissionsTableCheckbox({
    isDisabled: isDisabledProp,
    dataItem,
    field,
    dataIndex,
    onSelect
}: ClientsAndPermissionsTableCheckboxProps) {
    const id = dataIndex + (dataItem.Number || '') + field;
    const status = dataItem?.StatusCodeName;

    const checkbox = dataItem?.Features?.find((feature) => feature?.Name === field);
    const isChecked = checkbox?.IsSelected;
    const isPending = checkbox?.IsPending;
    const isDisabled =
        isDisabledProp ||
        isPending ||
        (status !== undefined && status !== 'Active' && field !== 'My Livingston Portal' && field !== 'Clearances');

    const isEmptyCell = !dataItem.Features.some((feature) => feature?.Name === field);

    if (isEmptyCell) return <td></td>;

    return (
        <td>
            <form className='d-flex justify-content-center'>
                <div className='custom-control custom-checkbox'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={id}
                        defaultChecked={isChecked || undefined}
                        disabled={isDisabled}
                        onChange={onSelect.bind(null, { clientServiceIndex: dataIndex, featureName: field || '' })}
                    />
                    <label className='custom-control-label' htmlFor={id} />
                </div>
            </form>
        </td>
    );
}
