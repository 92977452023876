import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckDownloadLobby, GetInitialDownloadLobby } from '../../../redux/actions/downloadLobby';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import { useDebounce } from '../../hooks/useDebounce';
import AlertToastCard from './AlertToastCard';
import DownloadLobbyToastCard from './DownloadLobbyToastCard';

const TIMEOUT = 8500;

const AlertsToasts = () => {
    const { alerts, downloadLobby } = usePortalSelector((state) => state.alerts);
    const dispatch = usePortalDispatch();

    const [showNotices, setShowNotices] = useState(false);
    const [showStatusAlerts, setShowStatusAlerts] = useState(false);
    const { pathname } = useLocation();

    const debounce = useDebounce(TIMEOUT);

    // Only toast the alerts on the initial render and hide them after 8500ms
    // Use localStorage to prevent them from showing on the page refresh
    useEffect(() => {
        debounce(() => {
            setShowNotices(false);
            setShowStatusAlerts(false);
        });
    }, [debounce]);

    // Logic to see if we should show alerts toast again
    // Two cases to toast the alerts:
    // 1. There are alerts, and they have never been viewed
    // 2. There are alerts and they have been viewed, but the view date is older than the newest alert
    useEffect(() => {
        const alertsToastTime = localStorage.getItem('alertsToastTime');

        // Status alerts
        if (alerts?.statusAlerts.length) {
            const mostRecentStatusAlert = alerts.statusAlerts[0];
            if (mostRecentStatusAlert) {
                if (!alertsToastTime || dayjs(mostRecentStatusAlert.date).utc() > dayjs(alertsToastTime).utc()) {
                    setShowStatusAlerts(true);
                }
            }
        }

        // Notices
        if (alerts?.notices.length) {
            const mostRecentNotice = alerts.notices[0];
            if (mostRecentNotice) {
                if (!alertsToastTime || dayjs(mostRecentNotice.date).utc() > dayjs(alertsToastTime).utc()) {
                    setShowNotices(true);
                }
            }
        }
    }, [alerts?.notices, alerts?.statusAlerts]);

    useEffect(() => {
        // Get initial download lobby
        dispatch(GetInitialDownloadLobby());
    }, [dispatch]);

    useEffect(() => {
        const checkInterval = setInterval(() => {
            const foundPackageId = downloadLobby.find((i) => i.inProgress)?.packageId;
            if (!foundPackageId) return;

            dispatch(CheckDownloadLobby(foundPackageId));
        }, 5000);

        return () => {
            clearInterval(checkInterval);
        };
    }, [dispatch, downloadLobby]);

    // Show all unread lobby items
    const unreadLobby = JSON.parse(localStorage.getItem('unreadLobby') || '[]') as string[];

    return (
        <>
            {alerts && (
                <div className='toasts-wrapper alert-toasts-wrapper' style={{ zIndex: 1001 }}>
                    {/* Download lobby */}
                    {downloadLobby.map(
                        (item) =>
                            (item.showToast ||
                                (unreadLobby.find((unread) => unread === item.packageId) &&
                                    (pathname === '/Clearances' || pathname === '/Documents'))) && (
                                <DownloadLobbyToastCard key={item.packageId} {...item} />
                            )
                    )}

                    {/* Status alerts */}
                    {showStatusAlerts &&
                        alerts.statusAlerts.map((alert) => (
                            <AlertToastCard
                                key={alert.id}
                                type={alert.type}
                                date={alert.date}
                                entryNumber={alert.entryNumber}
                                agencyKey={alert.agencyKey}
                                customsInfoID={alert.customsInfoID}
                            />
                        ))}

                    {/* Notices */}
                    {showNotices &&
                        alerts.notices.map((alert) => (
                            <AlertToastCard
                                key={alert.id}
                                type={alert.type}
                                title={alert.title}
                                date={alert.date}
                                description={alert.description}
                            />
                        ))}
                </div>
            )}
        </>
    );
};

export default AlertsToasts;
