import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { useHistory } from 'react-router-dom';
import { NotificationsLabels } from '../../../../../common/constants/constants-portal';
import Badge from '../../../../../common/features/Badge';
import CarrierIcon from '../../../../../common/features/CarrierIcon';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { DestinationLabels, MOTCodes, MOTMap } from '../../../common/constants/constants-clearances';
import { parseDate } from '../../../common/functions/parser';
import { ShipmentListItem } from '../../../common/models/ShipmentList';
import { useClearancesSelector } from '../../../redux/hooks';
import { ActionButton } from './ActionButton';

interface FormattedGridCellProps extends GridCellProps {
    dataItem: ShipmentListItem;
    checkboxChange?: (payload: CheckboxChangeEvent) => void;
    selectedEntries?: string[];
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    checkboxChange,
    selectionChange,
    selectedEntries,
    className,
    style,
    ...rest
}) => {
    const history = useHistory();
    const translate = useTranslation();
    let cellContent;
    const { allSelectedEntries } = useClearancesSelector((state) => state.shipmentsWithTotals);
    const { user } = useClearancesSelector((state) => state.hydration);

    const handleClick = () => {
        if (selectedEntries?.find((selected) => selected === dataItem.EntryNumber)) {
            return;
        } else if (!['Actions', 'selected'].includes(field)) {
            const customsInfoID = dataItem.CustomsInfoID;
            history.push(`/Clearances/${customsInfoID}`);
        }
    };
    switch (field) {
        case 'ModeOfTransport':
            cellContent = <CarrierIcon mot={MOTCodes[dataItem[field].id as keyof typeof MOTCodes]} MOTMap={MOTMap} />;
            break;
        case 'BillOfLading':
            cellContent = dataItem[field].MasterBill || null;
            break;
        case 'Milestone':
            const tooltip = NotificationsLabels[dataItem['Destination'] as keyof typeof NotificationsLabels].find(
                (item) => item.label === dataItem[field]
            );
            cellContent = tooltip ? (
                <Tooltip label={`${translate(tooltip.hint)}`}>
                    <span>{translate(tooltip.label)}</span>
                </Tooltip>
            ) : null;
            break;
        case 'ClearanceStatus':
            const status = dataItem[field];
            cellContent =
                status === 'IN_PROGRESS' ? (
                    <Badge type='info'>{translate('StatusInProgress_Label')}</Badge>
                ) : status === 'ON_HOLD' ? (
                    <Badge type='danger'>
                        <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />
                        {translate('StatusOnHold_Label')}
                    </Badge>
                ) : (
                    <Badge type='success'>{translate('StatusCompleted_Label')}</Badge>
                );
            break;
        case 'ModifiedOn':
            cellContent = parseDate(dataItem[field]);
            break;
        case 'Destination':
            cellContent = translate(DestinationLabels[dataItem[field] as keyof typeof DestinationLabels]);
            break;
        case 'selected':
            cellContent = (
                <Checkbox
                    name={dataItem.EntryNumber ? dataItem?.EntryNumber : field}
                    disabled={
                        !!allSelectedEntries &&
                        !allSelectedEntries.some((select) => select === dataItem.EntryNumber) &&
                        !!user &&
                        !!user.PortalClearancesMaxItemDownloadNumber &&
                        allSelectedEntries?.length >= user?.PortalClearancesMaxItemDownloadNumber
                    }
                    value={
                        allSelectedEntries && allSelectedEntries?.length > 0
                            ? allSelectedEntries.some((select) => select === dataItem.EntryNumber)
                            : dataItem.selected
                    }
                    onChange={checkboxChange}
                />
            );
            break;
        case 'ReferenceNumber':
            if (dataItem[field as keyof ShipmentListItem] === '') {
                cellContent = dataItem[field as keyof ShipmentListItem]?.toString();
            } else if (!isNaN(Number(dataItem[field as keyof ShipmentListItem]))) {
                cellContent = Number(dataItem[field as keyof ShipmentListItem])?.toString();
            } else {
                cellContent = dataItem[field as keyof ShipmentListItem]?.toString();
            }
            break;
        case 'Actions':
            cellContent = (
                <ActionButton
                    placement='left-start'
                    dataItem={dataItem}
                    disabled={!!selectedEntries?.find((selected) => selected === dataItem.EntryNumber)}
                    {...rest}
                />
            );
            break;
        default:
            cellContent = dataItem[field as keyof ShipmentListItem]?.toString();
    }

    return (
        <td
            className={`${className} ${
                selectedEntries?.find((selected) => selected === dataItem.EntryNumber) ? 'k-master-row k-state-selected' : 'k-master-row'
            }`}
            style={{
                ...style,
                textAlign: field === 'selected' ? 'center' : 'left',
                cursor: `${
                    field !== 'selected'
                        ? selectedEntries?.find((selected) => selected === dataItem.EntryNumber)
                            ? 'default'
                            : 'pointer'
                        : 'default'
                }`
            }}
            onClick={handleClick}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
