import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../../../redux/api/apiSlice';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import clientSelectionReducer from '../../../redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import portalSolutionsReducers from '../../../redux/reducers/portalSolutionsSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import productReportGridSettingsReducer from '../../productReport/redux/reducers/productReportGridSettingsSlice';
import productReportReducer from '../../productReport/redux/reducers/productReportSlice';

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,

        // page specific slices
        productReport: productReportReducer,
        productReportGridSettings: productReportGridSettingsReducer
    },
    middleware: (gDM) => gDM().concat(apiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
