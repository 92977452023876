import type { MouseEvent } from 'react';
import { forwardRef } from 'react';

const VARIANTS = {
    primary: 'primary',
    secondary: 'secondary',
    tertiary: 'tertiary',
    'tertiary-blue': 'tertiary-blue',
    'tertiary-white': 'tertiary-white',
    ghost: 'ghost',
    'ghost-dark': 'ghost-dark',
    danger: 'danger',
    'danger-outline': 'outline-danger',
    'danger-ghost': 'ghost-danger',
    link: 'link'
};

const SIZES = {
    small: 'btn-sm',
    medium: '',
    large: 'btn-lg'
};

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, React.AriaAttributes {
    variant: keyof typeof VARIANTS;
    size?: keyof typeof SIZES;
    'data-toggle'?: string;
    'data-display'?: string;
    'data-target'?: string;
    'data-dismiss'?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = 'primary',
            size = 'medium',
            type = 'button',
            className = '',
            disabled,
            'data-toggle': dataToggle,
            'data-display': dataDisplay,
            'data-target': dataTarget,
            'data-dismiss': dataDismiss,
            onClick,
            children,
            ...rest
        },
        ref
    ) => {
        const handleMouseUp = (e: MouseEvent<HTMLButtonElement>) => {
            let button = e.target as HTMLButtonElement;
            button.blur();
        };

        return (
            <button
                type={type}
                className={`${variant !== 'link' ? 'btn' : 'font-weight-normal'} btn-${VARIANTS[variant]} ${SIZES[size]} ${className}`}
                onClick={onClick}
                onMouseUp={handleMouseUp}
                ref={ref}
                style={
                    variant === 'link'
                        ? { ...rest.style, zIndex: 0, backgroundColor: 'inherit', border: 'none' }
                        : { ...rest.style, zIndex: 0 }
                }
                data-toggle={dataToggle}
                data-display={dataDisplay}
                data-target={dataTarget}
                data-dismiss={dataDismiss}
                disabled={disabled}
                {...rest}
            >
                {children}
            </button>
        );
    }
);

export default Button;
