import type { FormEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setPageSelected as setPageSelectedClearances } from '../../../pages/clearances/redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../pages/clearances/redux/reducers/shipmentStatusSlice';
import { setPageSelected as setPageSelectedProfile } from '../../../pages/profile/redux/reducers/profileGridSettingsSlice';
import { setPageSelected as setPageSelectedSubscribers } from '../../../pages/subscribers/redux/reducers/subscribersGridSettingsSlice';
import { GetClientSelectionSettings, UpdateClientSelectionSettings } from '../../../redux/actions/clientSelection';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import { resetClientSelectionState, setIsApplyClicked } from '../../../redux/reducers/clientSelectionReducer';
import { setUserCountryCode } from '../../../redux/reducers/hydrationSlice';
import { REACT_APP_ASSETS_URL } from '../../constants/constants-portal';
import useDropdownToggle from '../../hooks/useDropdownToggle';
import useTranslation from '../../hooks/useTranslation';
import Spinner from '../Spinner';
import useToast from '../Toast/useToast';
import classes from './ClientChooser.module.css';
import CountrySwitcher from './components/CountrySwitcher';
import SearchBar from './components/SearchBar';
import SelectionOptions from './components/SelectionOptions';

export interface ClientChooserRowOption {
    name: string;
    selected: boolean;
    caption: string;
    fullNumber: string;
}

export interface ClientChooserRow {
    name: string;
    country: 'ca' | 'us';
    number: string;
    fullNumber: string;
    options: ClientChooserRowOption[];
}

export default function ClientChooser() {
    const translate = useTranslation();

    const location = useLocation();

    const dispatch = usePortalDispatch();
    const { user } = usePortalSelector((state) => state.hydration);
    const { clientSelection, initialClientSelection, isLoadingGet, isLoadingUpdate } = usePortalSelector((state) => state.clientSelection);
    const isUnrestricted = clientSelection?.HasUnrestrictedAccess || clientSelection?.ServicesLimitReached || false;
    const selectedCountryCodeInitial = initialClientSelection?.Countries.find(({ IsSelected }) => IsSelected)?.Code;
    const totalClientsAmountInitial = initialClientSelection?.ClientServices.filter(
        ({ CountryCode }) => CountryCode === selectedCountryCodeInitial
    ).length;
    const selectedClientsAmountInitial = initialClientSelection?.ClientServices.filter(
        ({ IsSelected, CountryCode }) => IsSelected && CountryCode === selectedCountryCodeInitial
    ).length;
    const selectedCountryCode = clientSelection?.Countries.find(({ IsSelected }) => IsSelected)?.Code;

    const [searchQuery, setSearchQuery] = useState('');

    const form = useRef<HTMLFormElement>(null);
    const { isOpen, handleToggle, setIsOpen } = useDropdownToggle(form);

    const { send: sendToast } = useToast();

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        dispatch(GetClientSelectionSettings());
    }, [dispatch]);

    const handleSubmit = useCallback(
        async (showToast: boolean, e?: FormEvent<HTMLFormElement>) => {
            e?.preventDefault();

            if (!clientSelection) return;

            const clientServices = !isUnrestricted
                ? clientSelection?.ClientServices
                : clientSelection?.ClientServices.filter(({ IsSelected }) => IsSelected);

            const res = await dispatch(
                UpdateClientSelectionSettings({
                    ServicesLimitReached: clientSelection?.ServicesLimitReached,
                    MaxSelectedServicesPerCountry: clientSelection?.MaxSelectedServicesPerCountry,
                    HasUnrestrictedAccess: isUnrestricted,
                    Countries: clientSelection?.Countries,
                    ClientServices: clientServices
                })
            );

            await dispatch(GetClientSelectionSettings());

            dispatch(setIsApplyClicked(true));

            if (selectedCountryCode) {
                dispatch(setUserCountryCode(selectedCountryCode));
            }

            if (res?.meta?.requestStatus && showToast) {
                sendToast({ message: translate('ClientSelectionUpdated_Label'), type: 'success' });
            }

            // For clearances grid
            if (location.pathname.includes('/Clearances')) {
                dispatch(setPageSelectedClearances(1));
                dispatch(resetStatus());
            }

            // For subscribers grid
            if (location.pathname.includes('/Subscribers')) {
                dispatch(setPageSelectedSubscribers(1));
            }

            // For profile grid
            if (location.pathname.includes('/Profile')) {
                dispatch(setPageSelectedProfile(1));
            }

            if (isMounted.current) {
                setIsOpen(false);
                setSearchQuery('');
            }
        },
        [clientSelection, isUnrestricted, dispatch, selectedCountryCode, location.pathname, setIsOpen, sendToast, translate]
    );

    const initialRender = useRef(true);

    // Click apply on initial render because sometimes user CountryCode is not equal to clientSelection CountryCode
    useEffect(() => {
        if (!selectedCountryCode || !initialRender.current) {
            return;
        }

        if (user?.CountryCode !== selectedCountryCode) {
            handleSubmit(false);
        }

        initialRender.current = false;
    }, [handleSubmit, selectedCountryCode, setIsOpen, user?.CountryCode]);

    const handleCancel = async () => {
        dispatch(resetClientSelectionState());
        setIsOpen(false);
        setSearchQuery('');
    };

    const initialClientsSelectedLabel = translate('ClientsSelected_Label', selectedClientsAmountInitial, totalClientsAmountInitial);
    const initialClientsSelectedLabelSplit = typeof initialClientsSelectedLabel === 'string' ? initialClientsSelectedLabel?.split(' ') : [];

    const isLoading = isLoadingGet || isLoadingUpdate;

    // Display nothing while data is loading
    if (!clientSelection && isLoadingGet) return null;

    return (
        <div className='dropdown mr-1 mr-md-0'>
            {/* Dropdown Button */}
            <div className='btn btn-tertiary dropdown-toggle d-flex flex-row align-items-center' onClick={handleToggle}>
                <span className='dropdown-btn-flag'>
                    {selectedCountryCodeInitial && (
                        <img
                            src={`${REACT_APP_ASSETS_URL}/assets/images/icons/flags/${selectedCountryCodeInitial}-flag.svg`}
                            alt='USA flag'
                        />
                    )}
                </span>
                <span>{initialClientsSelectedLabelSplit[0]}</span>
                <span className={`ml-1 ${classes['visible-label-large']}`}>{`${selectedCountryCodeInitial?.toUpperCase()} ${
                    initialClientsSelectedLabelSplit[1]
                } ${initialClientsSelectedLabelSplit[2]}`}</span>
            </div>
            {isOpen && (
                <form
                    className={`dropdown-menu dropdown-menu-right p-0 ${classes.dropdown}`}
                    ref={form}
                    onSubmit={handleSubmit.bind(null, true)}
                >
                    <div className='card flex-1 shadow-none d-flex flex-column overflow-hidden'>
                        {/* Select country */}
                        <CountrySwitcher
                            countries={clientSelection?.Countries}
                            selectedCountryCode={selectedCountryCode}
                            setSearchQuery={setSearchQuery}
                        />
                        {/* Search Bar */}
                        <SearchBar
                            isUnrestricted={isUnrestricted}
                            selectedCountryCode={selectedCountryCode}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />
                        {/* Selection Options */}
                        <SelectionOptions
                            isUnrestricted={isUnrestricted}
                            searchQuery={searchQuery}
                            selectedCountryCode={selectedCountryCode}
                        />
                        {/* Action Buttons */}
                        <div className='d-flex justify-content-center flex-grow-0 px-4 pt-4 pb-3'>
                            <button type='button' className='btn btn-secondary btn-md flex-grow-1 mr-1 w-50' onClick={handleCancel}>
                                {translate('Cancel_Label')}
                            </button>
                            <button
                                type='submit'
                                className={`
                                    btn btn-primary btn-md flex-grow-1 ml-1 w-50
                                    ${isLoading ? 'disabled' : ''}
                                `}
                                disabled={isLoading}
                            >
                                {isLoading ? <Spinner size='small' color='white' /> : translate('Apply_Label')}
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
}
