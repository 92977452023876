import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router-dom';
import Badge from '../../../common/features/Badge';
import { createDateForAlert } from '../../../common/functions/parser';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { usePortalSelector } from '../../../redux/hooks';
import { AgencyList } from '../../constants/constants-portal';
import Button from '../Button';

interface AlertDrawerCardProps {
    type: string;
    date: string;
    title?: string;
    description?: string;
    entryNumber?: string;
    agencyKey?: string;
    customsInfoID?: number;
    handleClose?: () => void;
}

const AlertDrawerCard: React.FC<AlertDrawerCardProps> = ({
    type,
    title,
    date,
    description = '',
    entryNumber,
    agencyKey,
    customsInfoID,
    handleClose
}) => {
    const { languageSelected } = usePortalSelector((state) => state.language);

    const translate = useTranslationHydration();

    const history = useHistory();

    const handleViewEntry = () => {
        history.push(`/Clearances/${customsInfoID}`);
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <div className='alert-card bg-white p-1 my-2'>
            <div className='card-content d-flex flex-column align-items-start p-3'>
                <header className='d-flex w-100 flex-fill justify-content-between'>
                    {/* Badge: Shipment on hold */}
                    {type.toUpperCase() === 'ON_HOLD' && (
                        <Badge className='mb-2' type='danger'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />
                            {translate('ShipmentOnHold_Label')}
                        </Badge>
                    )}
                    {/* Badge: In progress */}
                    {type.toUpperCase() === 'IN_PROGRESS' && (
                        <Badge className='mb-2' type='info'>
                            {translate('StatusInProgress_Label')}
                        </Badge>
                    )}
                    {/* Badge: Livingston notice */}
                    {type.toUpperCase() === 'NOTICE' && (
                        <Badge className='mb-2' type='neutral'>
                            {translate('LivingstonNoticeTitle_Label')}
                        </Badge>
                    )}
                    {/* Date */}
                    <p
                        className='alert-card-date m-0'
                        style={{ marginBottom: '0.5rem', marginLeft: '1rem', position: 'relative', top: '0px', right: '0px' }}
                    >
                        {createDateForAlert(date, languageSelected?.locale)}
                    </p>
                </header>

                {/* Title */}
                <p className='alert-card-title mb-2' style={{ paddingRight: 0 }}>
                    <strong>
                        {type.toUpperCase() === 'ON_HOLD' &&
                            translate('AlertsOnHold_Label', entryNumber, translate(AgencyList[agencyKey as keyof typeof AgencyList].label))}
                        {type.toUpperCase() === 'IN_PROGRESS' &&
                            translate(
                                'AlertsInProgress_Label',
                                entryNumber,
                                translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                            )}
                        {type.toUpperCase() === 'NOTICE' && title}
                    </strong>
                </p>
                {/* Description */}
                {type.toUpperCase() === 'ON_HOLD' && <p className='alert-card-desc mb-0'>{translate('HoldAlert_Label')}</p>}
                {type.toUpperCase() === 'IN_PROGRESS' && <p className='alert-card-desc mb-0'>{translate('AlertsReviewMessage_Label')}</p>}
                {type.toUpperCase() === 'NOTICE' && (
                    <p
                        className='alert-card-desc mb-0'
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description.replace(/&nbsp;/g, ' ')) }}
                    ></p>
                )}
                {/* View entry link */}
                {(type.toUpperCase() === 'ON_HOLD' || type.toUpperCase() === 'IN_PROGRESS') && (
                    <Button variant='tertiary-blue' className='mt-3' onClick={handleViewEntry}>
                        {translate('ViewEntry_Label')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default AlertDrawerCard;
