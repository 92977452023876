import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useHistory } from 'react-router-dom';
import { TabNavigationIds } from '../../../common/constants/constants-portal';
import Badge from '../../../common/features/Badge';
import Tooltip from '../../../common/features/Tooltip/Tooltip';
import useTranslation from '../../../common/hooks/useTranslation';
import { MilestoneTooltips } from '../comon/overviewDashboardFunctionsAndConstants';

interface FormattedGridCellProps extends GridCellProps {
    onClickFunction?(): void;
}
const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    onClickFunction,
    field = '',
    dataItem,
    selectionChange,
    className,
    style,
    ...rest
}) => {
    let cellContent;
    const translate = useTranslation();
    const history = useHistory();

    switch (field) {
        case 'Entry No.':
            cellContent = dataItem['EntryNumber'];
            break;
        case 'Cargo control No.':
            cellContent = dataItem['CargoControlNumber'];
            break;
        case 'BOL No.':
            cellContent = dataItem['BillOfLading']?.MasterBill;
            break;
        case 'Status':
            const status = dataItem['ClearanceStatus'];
            cellContent =
                status === 'IN_PROGRESS' ? (
                    <Badge type='info'>{translate('StatusInProgress_Label')}</Badge>
                ) : status === 'ON_HOLD' ? (
                    <Badge type='danger'>
                        <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />
                        {translate('StatusOnHold_Label')}
                    </Badge>
                ) : (
                    <Badge type='success'>{translate('StatusCompleted_Label')}</Badge>
                );
            break;
        case 'Current milestone':
            cellContent = (
                <Tooltip label={translate(MilestoneTooltips(dataItem['Milestone']))}>
                    <span>{translate(dataItem['Milestone'])}</span>
                </Tooltip>
            );
            break;
    }
    return (
        <td
            onClick={
                field === 'Entry No.'
                    ? () => {
                          const customsInfoID = dataItem.CustomsInfoID;
                          localStorage.setItem('activeTab', TabNavigationIds.Clearances[0]);
                          history.push(`../Clearances/${customsInfoID}`);
                      }
                    : () => {}
            }
            style={{
                ...style,
                ...(field === 'Entry No.' ? { paddingLeft: '34px' } : {})
            }}
        >
            {cellContent}
        </td>
    );
};

export { FormattedGridCell }; // Add this export statement
