import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { imagingPackageStatusType } from '../../common/constants/constants-portal';
import type { Alert } from '../../common/models/Alert';
import type { Notice } from '../../common/models/Notice';
import type { AlertsSlice } from '../../common/models/ReduxSlices';
import { CheckDownloadLobby, GetInitialDownloadLobby } from '../actions/downloadLobby';

const initialState: AlertsSlice = {
    alerts: null,
    alertsReadTime: localStorage.getItem('alertsReadTime'),
    unreadAlerts: 0,
    downloadLobby: []
};

// Get the sum of unread items by looping through array and comparing the date from each item to the alertsReadTime date
const getUnreadItems = (arr: Alert[] | Notice[], alertsReadTime: string) => {
    let result = 0;
    for (const item of arr) {
        if (dayjs(item.date).utc() > dayjs(alertsReadTime).utc()) {
            result += 1;
        }
    }
    return result;
};

const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setAlerts(state, action: PayloadAction<{ statusAlerts: Alert[]; notices: Notice[] }>) {
            return {
                ...state,
                alerts: action.payload,
                unreadAlerts:
                    state.alertsReadTime !== null
                        ? getUnreadItems(action.payload.statusAlerts, state.alertsReadTime) +
                          getUnreadItems(action.payload.notices, state.alertsReadTime)
                        : action.payload.statusAlerts.length + action.payload.notices.length
            };
        },
        readAlerts(state, action) {
            return { ...state, unreadAlerts: 0, alertsReadTime: action.payload };
        },
        addToDownloadLobby(state, action: PayloadAction<string>) {
            const found = state.downloadLobby.find((i) => i.packageId === action.payload);
            if (found) return state;

            // Add new alert to localStorage
            const unreadLobby = JSON.parse(localStorage.getItem('unreadLobby') || '[]') as string[];
            unreadLobby.push(action.payload);
            localStorage.setItem('unreadLobby', JSON.stringify(unreadLobby));

            return {
                ...state,
                downloadLobby: [
                    {
                        packageId: action.payload,
                        inProgress: true,
                        imageUrl: '',
                        showToast: true,
                        createdOn: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }),
                        documentCount: 0,
                        entryNumbers: [],
                        hasError: false
                    },
                    ...state.downloadLobby
                ]
            };
        },
        removeUnreadLobbyItems(state) {
            // Remove all unread download lobby items
            const unreadLobby = JSON.parse(localStorage.getItem('unreadLobby') || '[]') as string[];
            const filteredLobby = unreadLobby.filter((unread) => state.downloadLobby.find((item) => item.packageId === unread)?.inProgress);
            localStorage.setItem('unreadLobby', JSON.stringify(filteredLobby));

            return state;
        },
        removeUnreadLobbyItem(state, action: PayloadAction<string>) {
            // Remove unread download lobby item by packageId
            const unreadLobby = JSON.parse(localStorage.getItem('unreadLobby') || '[]') as string[];
            const filteredLobby = unreadLobby.filter((unread) => unread !== action.payload);
            localStorage.setItem('unreadLobby', JSON.stringify(filteredLobby));

            return state;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CheckDownloadLobby.pending, (state, action) => {
                // state.isLoading = true;
                // state.error = undefined;
            })
            .addCase(CheckDownloadLobby.fulfilled, (state, action) => {
                // Check if error
                if (action.payload.ImagingPackageStatusTypeID === 2 || action.payload.ImageUrl === '') {
                    state.downloadLobby = state.downloadLobby.map((p) =>
                        p.packageId === `${action.payload.PackageId}`
                            ? {
                                  ...p,
                                  inProgress: false,
                                  hasError: true,
                                  packageId: `${action.payload.PackageId}`
                                  //   imageUrl: action.payload.ImageUrl,
                                  //   showToast: true
                              }
                            : p
                    );
                }
                // Check if completed
                if (action.payload.ImagingPackageStatusTypeID === 4) {
                    state.downloadLobby = state.downloadLobby.map((p) =>
                        p.packageId === `${action.payload.PackageId}`
                            ? {
                                  ...p,
                                  inProgress: false
                                  //   imageUrl: action.payload.ImageUrl,
                                  //   showToast: true
                              }
                            : p
                    );
                }
            })
            .addCase(CheckDownloadLobby.rejected, (state, action) => {
                // state.isLoading = false;
                // if (typeof action.payload === 'number') {
                //     state.error = action.payload;
                // } else {
                //     state.error = action.error.message;
                // }
            })
            .addCase(GetInitialDownloadLobby.pending, (state, action) => {
                // state.isLoading = true;
                // state.error = undefined;
            })
            .addCase(GetInitialDownloadLobby.fulfilled, (state, action) => {
                if (Array.isArray(action.payload)) {
                    state.downloadLobby = action.payload.map((p) => ({
                        imageUrl: p.ImageUrl,
                        inProgress: [imagingPackageStatusType['New'], imagingPackageStatusType['InProgress']].includes(
                            p.ImagingPackageStatusTypeID
                        ),
                        packageId: `${p.PackageId}`,
                        showToast: false,
                        createdOn: p.CreatedDate,
                        documentCount: p.CountOfDocuments,
                        entryNumbers: p.EntryNumbers,
                        hasError: [
                            imagingPackageStatusType['FaultTerminated'],
                            imagingPackageStatusType['Cancelled'],
                            imagingPackageStatusType['OverLimit'],
                            imagingPackageStatusType['IncorrectPassword']
                        ].includes(p.ImagingPackageStatusTypeID)
                    }));
                }
            })
            .addCase(GetInitialDownloadLobby.rejected, (state, action) => {
                // state.isLoading = false;
                // if (typeof action.payload === 'number') {
                //     state.error = action.payload;
                // } else {
                //     state.error = action.error.message;
                // }
            });
    }
});

export const { setAlerts, readAlerts, addToDownloadLobby, removeUnreadLobbyItems, removeUnreadLobbyItem } = alertsSlice.actions;

export default alertsSlice.reducer;
