import { useMemo } from 'react';
import { useEdmClientServicesQuery } from '../../redux/api/teamApi';
import type { TeamMemberData } from '../models';

export const useGetParentServices = (parentServices: TeamMemberData['ParentServices']) => {
    const parentServiceNumbers = parentServices?.map((parentService) => parentService.Number) || [null];
    const { data: edmClientServicesData, isLoading } = useEdmClientServicesQuery(parentServiceNumbers, {
        skip: parentServiceNumbers.length === 1 && parentServiceNumbers[0] === null
    });
    const data = useMemo(
        () =>
            parentServices?.map((parentService) => {
                if (parentService.Number) {
                    const statusCodeName = edmClientServicesData?.[parentService.Number];
                    if (statusCodeName) {
                        return {
                            ...parentService,
                            ClientServices: parentService.ClientServices.map((clientService) => ({
                                ...clientService,
                                StatusCodeName: statusCodeName
                            }))
                        };
                    }
                }
                return parentService;
            }),
        [edmClientServicesData, parentServices]
    );

    return { data, isLoading };
};
