import {
    faAddressCard,
    faBoxOpen,
    faChartPie,
    faClipboardCheck,
    faCreditCard,
    faFileAlt,
    faFileContract,
    faFileExport,
    faFolderOpen,
    faHome,
    faList,
    faSignOutAlt,
    faUserCircle,
    faUserEdit,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import { NewSubscriber } from '../models/NewSubscriber';

// Digital Design System (DDS)
export const REACT_APP_ASSETS_URL = 'https://cdn.livingstonintl.com/digital-design/qa';
export const REACT_APP_ASSETS_URL_CSS = `${REACT_APP_ASSETS_URL}/main-2.7.css`;
//

// API base paths
export const API_DASHBOARD_PATH = '/api/dashboard';
export const API_HYDRATION_PATH = '/api/hydration';
export const API_CLEARANCES_PATH = '/api/clearance';
export const API_PROFILE_PATH = '/api/profile';
export const API_TEAM_PATH = '/TeamManagement';
export const API_CLIENT_ADMIN_PATH = '/api/clientAdmin';
export const API_SEARCH_SHIPMENT_PATH = '/SearchShipment';
export const API_IMAGING_PATH = '/Imaging';
export const API_CASS_PATH = '/api/SearchShipmentCASS';

// Breakpoints for responsive layout
export const Breakpoints = { Mobile: 1024, BootstrapLarge: 991 };

export const Translations = {
    LanguageCodes: {
        English: 'en',
        French: 'fr'
    },
    PhraseGroups: {
        Hydration: 'MLP.Hydration',
        Team: 'MLP.Team',
        Clearances: 'MLP.Clearances',
        Profile: 'MLP.Profile',
        Subscribers: 'MLP.Subscribers',
        Contacts: 'MLP.Contacts',
        Documents: 'MLP.Documents',
        CompleteEntryData: 'MLP.CAD',
        Dashboard: 'MLP.Dashboard',
        Faq: 'MLP.FAQ',
        ProductReport: 'MLP.ProductReport'
    }
};

// Agency list US + CA
export const AgencyList = {
    FDA: { label: 'AgencyFDA_Label', customsInfoLabel: 'FdaInfo_Label', viewInfoLabel: 'FdaViewInfo_Label' },
    FDAPN: { label: 'AgencyFDA_Label', customsInfoLabel: 'FdaInfo_Label', viewInfoLabel: 'FdaViewInfo_Label' },
    AMS: { label: 'AgencyAMS_Label', customsInfoLabel: 'AmsInfo_Label', viewInfoLabel: 'AmsViewInfo_Label' },
    APHISCore: { label: 'AgencyAPHISCore_Label', customsInfoLabel: 'AphisInfo_Label', viewInfoLabel: 'AphisViewInfo_Label' },
    APHISLacey: { label: 'AgencyAPHISLacey_Label', customsInfoLabel: 'AphisInfo_Label', viewInfoLabel: 'AphisViewInfo_Label' },
    ATF: { label: 'AgencyATF_Label', customsInfoLabel: 'AtfInfo_Label', viewInfoLabel: 'AtfViewInfo_Label' },
    CPSC: { label: 'AgencyCPSC_Label', customsInfoLabel: 'CpscInfo_Label', viewInfoLabel: 'CpscViewInfo_Label' },
    DDTC: { label: 'AgencyDDTC_Label', customsInfoLabel: 'DdtcInfo_Label', viewInfoLabel: 'DdtcViewInfo_Label' },
    DEA: { label: 'AgencyDEA_Label', customsInfoLabel: 'DeaInfo_Label', viewInfoLabel: 'DeaViewInfo_Label' },
    EPA: { label: 'AgencyEPA_Label', customsInfoLabel: 'EpaInfo_Label', viewInfoLabel: 'EpaViewInfo_Label' },
    FSIS: { label: 'AgencyFSIS_Label', customsInfoLabel: 'FsisInfo_Label', viewInfoLabel: 'FsisViewInfo_Label' },
    FWS: { label: 'AgencyFWS_Label', customsInfoLabel: 'FwsInfo_Label', viewInfoLabel: 'FwsViewInfo_Label' },
    NHTSA: { label: 'AgencyNHTSA_Label', customsInfoLabel: 'NhtsaInfo_Label', viewInfoLabel: 'NhtsaViewInfo_Label' },
    NMFS: { label: 'AgencyNMFS_Label', customsInfoLabel: 'NmfsInfo_Label', viewInfoLabel: 'NmfsViewInfo_Label' },
    OMC: { label: 'AgencyOMC_Label', customsInfoLabel: 'OmcInfo_Label', viewInfoLabel: 'OmcViewInfo_Label' },
    TTB: { label: 'AgencyTTB_Label', customsInfoLabel: 'TtbInfo_Label', viewInfoLabel: 'TtbViewInfo_Label' },
    Customs_US: { label: 'AgencyCustomsUS_Label', customsInfoLabel: 'CustomsInfo_Label', viewInfoLabel: 'CustomsViewInfo_Label' },
    Customs_CA: { label: 'AgencyCustomsCA_Label', customsInfoLabel: 'CustomsInfo_Label', viewInfoLabel: 'CustomsViewInfo_Label' },
    Customs: { label: 'CusInfoCustomsHold', customsInfoLabel: 'CustomsInfo_Label', viewInfoLabel: 'CustomsViewInfo_Label' },
    APHIS: { label: 'AgencyAPHISCore_Label', customsInfoLabel: 'AphisInfo_Label', viewInfoLabel: 'AphisViewInfo_Label' }
};

// Language Codes
export const LanguageCodes = [
    { label: 'English_Label', code: 'en', country: 'US', locale: 'en-US' },
    { label: 'French_Label', code: 'fr', country: 'CA', locale: 'fr-CA' }
];

export const PortalRoutes = {
    dashboard: '/OverviewDashboard',
    clearances: '/Clearances',
    subscribers: '/Subscribers',
    documents: '/Documents',
    profile: '/Profile',
    team: '/Team',
    contacts: '/Contacts',
    editClientAdmin: '/AccessManagement/AccessManagement/SearchUsers',
    termsOfUse: '/TermsOfUse',
    signOut: '/Auth/SignOut',
    completeEntryData: '/CompleteEntryData',
    faq: '/PortalFAQ',
    productReport: '/ProductReport'
} as const;

// Sidebar internal navigation items
export const SidebarNavItems = [
    { id: 'dashboard', label: 'Dashboard_Label', url: PortalRoutes.dashboard, icon: faHome },
    { id: 'clearances', label: 'Clearances_Label', url: PortalRoutes.clearances, icon: faBoxOpen },
    { id: 'subscribers', label: 'Subscribers_Label', url: PortalRoutes.subscribers, icon: faUsers },
    { id: 'documents', label: 'Documents_Label', url: PortalRoutes.documents, icon: faFolderOpen },
    { id: 'productReport', label: 'ProductReport_Label', url: PortalRoutes.productReport, icon: faFileExport }
];

// Header navigation items
export const HeaderNavItems = [
    { id: 'profile', label: 'Profile_Label', url: PortalRoutes.profile, icon: faUserCircle },
    { id: 'team', label: 'Team_Label', url: PortalRoutes.team, icon: faUsers },
    { id: 'contacts', label: 'Contacts_Label', url: PortalRoutes.contacts, icon: faAddressCard },
    { id: 'editClientAdmin', label: 'EditClientAdmin_Label', url: PortalRoutes.editClientAdmin, icon: faUserEdit },
    { id: 'termsOfUse', label: 'TermsOfUse_Label', url: PortalRoutes.termsOfUse, icon: faFileContract },
    { id: 'signOut', label: 'LogOut_Label', url: PortalRoutes.signOut, icon: faSignOutAlt }
] as const;

// Ids for Tab navigation
export const TabNavigationIds = {
    Clearances: [
        'clearances-status',
        'clearances-headerDetails',
        'clearances-lineDetails',
        'clearances-exceptions',
        'clearances-documents',
        'clearances-subscribers',
        'cad-details'
    ],
    Profile: ['profile-profile', 'profile-subscription'],
    Subscribers: ['subscriber-subscription']
};

// Notifications
export const USNotifications = [
    { key: 'HasNotificationsForDocumentsReceived', label: 'DocumentsReceived_Label', hint: 'DocumentsReceivedNotification_Label' },
    { key: 'HasNotificationsForCustomsCertified', label: 'CustomsCertified_Label', hint: 'CustomsCertifiedNotification_Label' },
    { key: 'HasNotificationsForCustomsReleased', label: 'CustomsReleased_Label', hint: 'CustomsReleasedUSNotification_Label' },
    { key: 'HasNotificationsForOneUsgReceived', label: 'OneUsgReceived_Label', hint: 'OneUsgReceivedNotification_Label' },
    { key: 'HasNotificationsForEntrySummaryAccepted', label: 'EntrySummaryAccepted_Label', hint: 'EntrySummaryAcceptedNotification_Label' },
    { key: 'HasNotificationsForClearanceOnHold', label: 'OnHold_Label', hint: 'ShipmentOnHoldNotification_Label' }
];

export const CANotifications = [
    { key: 'HasNotificationsForDocumentsReceived', label: 'DocumentsReceived_Label', hint: 'DocumentsReceivedNotification_Label' },
    { key: 'HasNotificationsForReleaseSubmitted', label: 'ReleaseSubmitted_Label', hint: 'ReleaseSubmittedNotification_Label' },
    { key: 'HasNotificationsForCustomsAccepted', label: 'CustomsAccepted_Label', hint: 'CustomsAcceptedNotification_Label' },
    { key: 'HasNotificationsForCustomsReleased', label: 'CustomsReleased_Label', hint: 'CustomsReleasedCANotification_Label' },
    { key: 'HasNotificationsForEntryAccepted', label: 'EntryAccepted_Label', hint: 'EntryAcceptedNotification_Label' },
    { key: 'HasNotificationsForClearanceOnHold', label: 'OnHold_Label', hint: 'ShipmentOnHoldNotification_Label' }
];

export const NotificationsLabels = {
    Canada: CANotifications,
    USA: USNotifications
};

// constants for add subscriber(s) modal
export const MILESTONES_US = {
    HasNotificationsForClearanceOnHold: true,
    HasNotificationsForCustomsCertified: false,
    HasNotificationsForCustomsReleased: false,
    HasNotificationsForDocumentsReceived: false,
    HasNotificationsForEntrySummaryAccepted: false,
    HasNotificationsForOneUsgReceived: false
};
export const MILESTONES_CA = {
    HasNotificationsForClearanceOnHold_CA: true,
    HasNotificationsForDocumentsReceived_CA: false,
    HasNotificationsForReleaseSubmitted_CA: false,
    HasNotificationsForCustomsAccepted_CA: false,
    HasNotificationsForCustomsReleased_CA: false,
    HasNotificationsForEntryAccepted_CA: false
};

export const emptySubscriber: NewSubscriber = {
    id: 0,
    email: '',
    emailIsValid: false,
    phone: '',
    phoneIsValid: false,
    fullName: '',
    role: '',
    language: '-'
};

export const NewSubscriberRoles = [
    { value: '', label: 'SelectRole_Label' },
    { value: 'Carrier', label: 'Carrier_Label' },
    { value: 'Supplier', label: 'Supplier_Label' },
    { value: 'Logistics', label: 'Logistics_Label' },
    { value: 'Customs', label: 'Customs_Label' },
    { value: 'Finance', label: 'Finance_Label' },
    { value: 'Manufacturer', label: 'Manufacturer_Label' },
    { value: 'Other', label: 'Other_Label' }
] as const;

export const NewSubscriberLanguages = [
    { value: '-', label: '' },
    { value: 'EN', label: '' },
    { value: 'FR', label: '' }
] as const;

export const ClearancesDestinations = {
    US: {
        id: 'US',
        label: 'ClearancesUSA_Label'
    },
    CA: {
        id: 'CA',
        label: 'ClearancesCanada_Label'
    }
};

export const ComboBoxTabOptions = [
    { id: 'All', label: 'StatusAll_Label' },
    { id: 'Team', label: 'Team_Label' },
    { id: 'Contacts', label: 'Contacts_Label' }
] as const;

export const appItems = [
    {
        Name: 'Clearance Analytics',
        Label: 'ClearanceAnalytics_Label',
        Icon: faChartPie,
        Order: 5
    },
    {
        Name: 'Create Shipping Docs USA',
        Label: 'CreateShippingDocsUSA_Label',
        Icon: faFileAlt
    },
    {
        Name: 'Clearance Tracker',
        Label: 'ClearanceTracker_Label',
        Icon: faClipboardCheck,
        Order: 2
    },
    {
        Name: 'Create Shipping Docs CA',
        Label: 'CreateShippingDocsCA_Label',
        Icon: faFileAlt,
        Order: 3
    },
    { Name: 'My Livingston Portal', Label: 'MyLivingstonPortal_Label', Icon: faList, Order: 4 },

    { Name: 'Make Payments', Label: 'MakePayments_Label', Icon: faCreditCard, Order: 1 }
];

export const shipmentDataSourceConstants = {
    us: 'EVENT_MANAGER',
    ca: 'REDSHIFT'
};

export const imagingPackageStatusType = {
    New: 1,
    FaultTerminated: 2,
    Cancelled: 3,
    Completed: 4,
    InProgress: 5,
    Submitted: 6,
    OverLimit: 7,
    IncorrectPassword: 8
};
