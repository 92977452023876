import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useParams } from 'react-router-dom';
import { shipmentDataSourceConstants, TabNavigationIds } from '../../../../common/constants/constants-portal';
import ErrorPage from '../../../../common/features/ErrorPage';
import Spinner from '../../../../common/features/Spinner';
import TabNav from '../../../../common/features/Tabs/TabNav';
import TabNavItem from '../../../../common/features/Tabs/TabNavItem';
import TabPanel from '../../../../common/features/Tabs/TabPanel';
import TabPanelItem from '../../../../common/features/Tabs/TabPanelItem';
import { checkAllFalsy } from '../../../../common/functions/utils';
import { useSearchParams } from '../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../common/hooks/useTranslation';
import { Document } from '../../../../common/models/ClearanceDocuments';
import { GetClientSelectionSettings } from '../../../../redux/actions/clientSelection';
import { documentToFileData } from '../../../documents/common/constants/constants-documents';
import { FileData, GroupedDocuments } from '../../../documents/common/models/ImagingModel';
import DocumentViewOverlay from '../../../documents/screens/DocumentsView/DocumentsViewOverlay';
import DocumentCompareModal from '../../../documents/screens/Modal/DocumentCompareModal';
import { DownloadModal } from '../../../documents/screens/Modal/DownloadModal';
import { configFilters } from '../../common/functions/documentModelFunctions';
import { formModalProp } from '../../common/functions/downloadModalFunctions';
import { ExportHeaderAndDetail } from '../../redux/actions/clearanceDocuments';
import { GetShipmentDetails } from '../../redux/actions/shipmentDetails';
import { useClearancesDispatch, useClearancesSelector } from '../../redux/hooks';
import { resetCadDetails } from '../../redux/reducers/cadDetailsSlice';
import { resetClearanceSubscribers } from '../../redux/reducers/clearanceSubscribersSlice';
import { setRenderCompareModal } from '../../redux/reducers/documentsSlice';
import { resetShipmentDetails } from '../../redux/reducers/shipmentDetailsSlice';
import CADDetailsGrid from './CADDetails/CADDetailsGrid';
import BreadcrumbClearances from './details/BreadcrumbClearances';
import ClearanceAlerts from './details/ClearanceAlerts';
import CustomsInfo from './details/CustomsInfo';
import MilestoneCard from './details/MilestoneCard';
import ShipmentDetailsCard from './details/ShipmentDetailsCard';
import ShipmentSummary from './details/ShipmentSummary';
import DocumentsGrid from './documents/DocumentsGrid';
import HeaderDetails from './headerDetails/HeaderDetails';
import LineDetails from './lineDetails/LineDetails';
import SubscriberToASingleClearanceTable from './subscribers/SubscribersGrid';

const ShipmentDetails = () => {
    const { shipmentDetails, isLoading, error } = useClearancesSelector((state) => state.shipmentDetails);
    const { user, shipmentDataSource } = useClearancesSelector((state) => state.hydration);
    const { initialClientSelection } = useClearancesSelector((state) => state.clientSelection);
    const { documentsIndex } = useClearancesSelector((state) => state.shipmentsWithTotals);
    const { headerDetails, renderCompareModal } = useClearancesSelector((state) => state.clearanceDocuments);
    const { viewImages } = useClearancesSelector((state) => state.viewImages);
    const selectedCountryCodeInitial = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;
    const [activeTab, setActiveTab] = useState('');
    const [downloadModalView, setDownloadModalView] = useState<null | string>(null);

    const [dataState, setDataState] = useState<({ ['selected']: boolean } & Document)[]>([]);
    let shipmentParam: any = useParams();
    const translate = useTranslation();
    const dispatch = useClearancesDispatch();

    const [searchParams] = useSearchParams();
    const origin = searchParams.get('origin');

    // On initial load get the shipment details and reset "shipmentDetails" and "clearancesSubscribers" slice on unmount
    useEffect(() => {
        dispatch(
            GetShipmentDetails({
                customsInfoID: shipmentParam.customsInfoID,
                GetClearanceNotificationsRequest: { ProfileEmail: user?.Email, ProfileMobileNumber: user?.MobileNumber }
            })
        );

        return () => {
            dispatch(resetShipmentDetails());
            dispatch(resetClearanceSubscribers());
            dispatch(resetCadDetails());
        };
    }, [dispatch, shipmentParam.customsInfoID, user?.Email, user?.MobileNumber]);

    // Fetch initial client selection if doesn't exist
    // We need selected country even when we refresh on this page
    useEffect(() => {
        dispatch(GetClientSelectionSettings());
    }, [dispatch]);

    const fileDataArray = documentToFileData(dataState ?? []);
    const groupedDocuments: GroupedDocuments[] = formModalProp(fileDataArray, selectedCountryCodeInitial ?? 'us');
    const handleTagEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'HL_DNLD_Details_Entry_CLR_CPEvt',
                location: 'Clearances'
            }
        });
    };

    const setCompareModalView = (param: null | string) => {
        dispatch(setRenderCompareModal(param));
    };

    const closeModal = (value: null) => {
        setDownloadModalView(null);
    };

    return (
        <>
            {/* Status: pending */}
            {isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {!isLoading && shipmentDetails && !error && !renderCompareModal && !viewImages.length && (
                <>
                    {/* Header */}
                    <header className='shadow position-relative' style={{ zIndex: '100' }}>
                        <BreadcrumbClearances origin={origin} />
                        <div className='single-clearance-header-bar px-4 py-4 py-sm-4'>
                            <ShipmentSummary />
                            <ClearanceAlerts />
                        </div>
                        <TabNav tabs={TabNavigationIds.Clearances} withDropdown>
                            <TabNavItem id={TabNavigationIds.Clearances[0]} onClick={() => setActiveTab('')}>
                                {translate('TableStatus_Label')}
                            </TabNavItem>
                            {selectedCountryCodeInitial === 'us' && true && (
                                <TabNavItem
                                    activeTab={activeTab}
                                    id={
                                        [TabNavigationIds.Clearances[1], TabNavigationIds.Clearances[2]].includes(activeTab)
                                            ? activeTab
                                            : ''
                                    }
                                    options={[
                                        {
                                            label: translate('ViewHeaderDetails_Label'),
                                            value: 'header-details',
                                            id: TabNavigationIds.Clearances[1],
                                            onClick: (id) => setActiveTab(id)
                                        },
                                        {
                                            label: translate('ViewLineDetails_Label'),
                                            value: 'line-details',
                                            id: TabNavigationIds.Clearances[2],
                                            onClick: (id) => setActiveTab(id)
                                        },
                                        selectedCountryCodeInitial === 'us'
                                            ? {
                                                  label: translate('DownloadDetailsButton_Label'),
                                                  value: 'download',
                                                  id: '',
                                                  onClick: () => {
                                                      if (
                                                          !documentsIndex?.Filters ||
                                                          !documentsIndex.ImageGroupings ||
                                                          !headerDetails ||
                                                          !checkAllFalsy(headerDetails)
                                                      )
                                                          return;
                                                      handleTagEvent();
                                                      dispatch(
                                                          ExportHeaderAndDetail({
                                                              shipments: [headerDetails],
                                                              filters: configFilters(documentsIndex, [shipmentDetails?.EntryNumber!])!,
                                                              imageGroupings: documentsIndex.ImageGroupings
                                                          })
                                                      );
                                                  }
                                              }
                                            : null
                                    ]}
                                >
                                    {activeTab === 'clearances-headerDetails'
                                        ? translate('HeaderDetails_Label')
                                        : activeTab === 'clearances-lineDetails'
                                        ? translate('LineDetails_Label')
                                        : translate('Details_Label')}
                                </TabNavItem>
                            )}
                            {false && (
                                <TabNavItem id={TabNavigationIds.Clearances[3]} onClick={() => setActiveTab('')}>
                                    {translate('Exceptions_Label')}
                                </TabNavItem>
                            )}
                            <TabNavItem id={TabNavigationIds.Clearances[4]} onClick={() => setActiveTab('')}>
                                {translate('DocumentsTab_Label')}
                            </TabNavItem>
                            <TabNavItem id={TabNavigationIds.Clearances[5]} onClick={() => setActiveTab('')}>
                                {translate('Subscribers_Label')}
                            </TabNavItem>
                            {shipmentDataSource === shipmentDataSourceConstants.ca && (
                                <TabNavItem id={TabNavigationIds.Clearances[6]} onClick={() => setActiveTab('')}>
                                    {translate('CADDetails_Label')}
                                </TabNavItem>
                            )}
                        </TabNav>
                    </header>
                    {/* Main content */}
                    <TabPanel>
                        <TabPanelItem id={TabNavigationIds.Clearances[0]}>
                            <div className='container-fluid mt-4'>
                                <div className='row'>
                                    <div className='col-12 col-lg-6'>
                                        <MilestoneCard />
                                        {shipmentDetails.PGAs?.filter((p) => p.Events && p.Events?.length).map((pga, index) => {
                                            return <CustomsInfo pga={pga} key={index} />;
                                        })}
                                    </div>
                                    <div className='col-12 col-lg-6'>
                                        <ShipmentDetailsCard />
                                    </div>
                                </div>
                            </div>
                        </TabPanelItem>

                        {selectedCountryCodeInitial === 'us' && (
                            <TabPanelItem id={TabNavigationIds.Clearances[1]}>
                                <HeaderDetails entryNo={shipmentDetails.EntryNumber!} />
                            </TabPanelItem>
                        )}
                        {selectedCountryCodeInitial === 'us' && (
                            <TabPanelItem id={TabNavigationIds.Clearances[2]}>
                                <LineDetails fileNo={shipmentDetails.FileNo!} />
                            </TabPanelItem>
                        )}
                        {/* <TabPanelItem id={TabNavigationIds.Clearances[3]}><>Exceptions</></TabPanelItem> */}
                        <TabPanelItem id={TabNavigationIds.Clearances[4]}>
                            <DocumentsGrid entryNo={shipmentDetails.EntryNumber!} dataState={dataState ?? []} setDataState={setDataState} />
                        </TabPanelItem>
                        <TabPanelItem id={TabNavigationIds.Clearances[5]}>
                            <SubscriberToASingleClearanceTable />
                        </TabPanelItem>
                        {shipmentDataSource === shipmentDataSourceConstants.ca && (
                            <TabPanelItem id={TabNavigationIds.Clearances[6]}>
                                <CADDetailsGrid />
                            </TabPanelItem>
                        )}
                    </TabPanel>
                </>
            )}
            {downloadModalView && (
                <DownloadModal
                    closeModal={closeModal}
                    selectedDocuments={dataState!.filter((item) => item.selected).map((item) => item.path)}
                    selectedCountry={selectedCountryCodeInitial}
                    formededObject={
                        documentToFileData(dataState ?? []).reduce((acc, x) => {
                            const key = x?._fileS3Path ?? 'defaultKey';
                            const arr: FileData[] = [x];
                            acc[key] = arr;
                            return acc;
                        }, {}) ?? {}
                    }
                    destructuredArrayForDownload={{
                        compareModalView: 'documentsGrid',
                        downloadModalView: 'download',
                        selectedEntries: [
                            documentToFileData(dataState ?? [])
                                .map((x: FileData) => {
                                    return selectedCountryCodeInitial === 'us' ? x._entryNumber ?? '' : x._transactionNumber ?? '';
                                })
                                .pop() ?? ''
                        ],
                        country: initialClientSelection?.Countries.find((c) => c.IsSelected),
                        modalView: null,
                        downloadModalData: [],
                        data: groupedDocuments
                    }}
                />
            )}
            {renderCompareModal && dataState.length > 1 && (
                <DocumentCompareModal
                    entryNumberClearances={shipmentDetails?.EntryNumber ?? ''}
                    isClearances={true}
                    downloadButtonClick={setDownloadModalView}
                    setCompareModalView={() => setCompareModalView(null)}
                    documentsCount={dataState.filter((x) => x.selected).length + ''}
                    entriesCount={dataState.filter((x) => x.selected).length + ''}
                    arrayOfFiles={documentToFileData(dataState ?? [])}
                />
            )}

            <DocumentViewOverlay setDownloadModalView={setDownloadModalView} isClearances={true} />

            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}
        </>
    );
};

export default ShipmentDetails;
