import { GridColumn as Column, Grid, GridCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GridLoadingOverlay from '../../../common/features/Grid/GridLoadingOverlay';
import useGridLanguage from '../../../common/hooks/useGridLanguage';
import useTranslation from '../../../common/hooks/useTranslation';
import { ClientSelectionSlice, HydrationSlice, LanguageSlice } from '../../../common/models/ReduxSlices';
import { ProductReportGridSettingsSlice, ProductReportSlice } from '../common/models/productReportModels';
import { setPageSelected, setPageSize, setSortFields } from '../redux/reducers/productReportGridSettingsSlice';
import { applySorting, setIsRedirecting, setProductReportData } from '../redux/reducers/productReportSlice';
import { RootState } from '../redux/store';
import { FormattedGridCell } from './productReport/FormatedGridCell';

const ProductReport = () => {
    const translate = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const gridLanguage = useGridLanguage(selectedLanguage);
    const SELECTED_FIELD = 'selected';
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    const gridSettings = useSelector<RootState, ProductReportGridSettingsSlice>((state) => state.productReportGridSettings);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { clientSelection, isLoadingGet, isLoadingUpdate } = useSelector<RootState, ClientSelectionSlice>(
        (state) => state.clientSelection
    );
    const { productReportData, isRedirecting } = useSelector<RootState, ProductReportSlice>((state) => state.productReport);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);

    // Redirect to Dashboard if US selected
    useEffect(() => {
        if (country?.Code === 'us' && !isLoadingUpdate && !isLoadingGet) {
            dispatch(setIsRedirecting(true));
            history.push('/OverviewDashboard');
        }
    }, [country?.Code, dispatch, history, isLoadingGet, isLoadingUpdate, isRedirecting]);

    useEffect(() => {
        /* Data only available for CA */
        const gridData = clientSelection?.ClientServices.filter((item) =>
            user?.InternalUser ? item.IsSelected && item.CountryCode === 'ca' : item.CountryCode === 'ca'
        );

        if (gridData) dispatch(setProductReportData(gridData));
    }, [clientSelection?.ClientServices, dispatch, user?.InternalUser]);

    // Initial sort
    useEffect(() => {
        dispatch(applySorting(gridSettings.columnSortFields));
    }, [dispatch, gridSettings.columnSortFields, productReportData]);

    const gridDataPerPage = useMemo(() => {
        if (!productReportData || !gridSettings) return [];
        const { pageSelected, pageSize } = gridSettings;

        return productReportData.slice((pageSelected - 1) * pageSize, pageSelected * pageSize);
    }, [productReportData, gridSettings]);

    // Sort Change
    const sortChange = (event: GridSortChangeEvent) => {
        dispatch(setSortFields(event.sort));
        dispatch(applySorting(event.sort));
    };

    // Pagination Section Change
    const pageChange = (event: GridPageChangeEvent) => {
        dispatch(setPageSelected(event.page.skip / event.page.take + 1));
        dispatch(setPageSize(event.page.take));
    };

    const handleHeaderCheckbox = useCallback(() => {}, []);

    /* Header */
    const customHeader = useCallback(
        (props: any) => {
            return props.field === 'selected' ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox value={headerCheckbox} onChange={handleHeaderCheckbox} />
                </div>
            ) : (
                <div className='d-flex justify-content-between grabbable' onClick={props.onClick}>
                    <div className='k-column-title'>{props.title}</div>
                    {props.children}
                </div>
            );
        },
        [headerCheckbox, handleHeaderCheckbox]
    );

    const formattedCell = (props: GridCellProps) => <FormattedGridCell {...props} />;

    return (
        <LocalizationProvider language={gridLanguage.language}>
            <IntlProvider locale={gridLanguage.locale}>
                <div className='d-flex position-relative flex-fill'>
                    <Grid
                        className='flex-fill'
                        data={gridDataPerPage}
                        pageSize={gridSettings.pageSize}
                        total={productReportData?.length}
                        skip={(gridSettings.pageSelected - 1) * gridSettings.pageSize}
                        take={gridSettings.pageSize}
                        pageable={productReportData && productReportData?.length <= 10 ? false : gridSettings.pageable}
                        onPageChange={pageChange}
                        sortable={{
                            allowUnsort: false
                        }}
                        sort={gridSettings.columnSortFields}
                        onSortChange={sortChange}
                    >
                        <Column field={SELECTED_FIELD} cell={formattedCell} headerCell={customHeader} width='50px' orderIndex={0} />

                        <Column
                            key={'Number'}
                            field={'Number'}
                            title={'Client ID'}
                            // headerCell={customHeader}
                            sortable={true}
                            cell={formattedCell}
                        />
                        <Column
                            key={'Name'}
                            field={'Name'}
                            title={'Cient Name'}
                            // headerCell={customHeader}
                            sortable={true}
                            cell={formattedCell}
                        />
                        <Column
                            field='Download'
                            title={' '}
                            headerCell={undefined}
                            cell={formattedCell}
                            sortable={false}
                            resizable={false}
                            width='50%'
                        />
                    </Grid>
                    {isLoadingGet && <GridLoadingOverlay />}
                </div>
            </IntlProvider>
        </LocalizationProvider>
    );
};

export default memo(ProductReport);
