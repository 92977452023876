import { SortDescriptor } from '@progress/kendo-data-query';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductReportData, ProductReportSlice } from '../../common/models/productReportModels';

const initialState: ProductReportSlice = {
    productReportData: [],
    productReportDataPerPage: [],
    isRedirecting: false
};

const productReportSlice = createSlice({
    name: 'productReportSlice',
    initialState,
    reducers: {
        setProductReportData(state, action: PayloadAction<ProductReportData[]>) {
            state.productReportData = action.payload;
        },
        applySorting(state, action: PayloadAction<SortDescriptor[]>) {
            state.productReportData = state.productReportData?.sort((a, b) => {
                const x = action.payload[0].dir === 'asc' ? a : b;
                const y = action.payload[0].dir === 'asc' ? b : a;

                if (action.payload[0].field === 'Number') {
                    const numX = typeof Number(x.Number) === 'number' ? Number(x.Number) : 0;
                    const numY = typeof Number(y.Number) === 'number' ? Number(y.Number) : 0;

                    return numX - numY;
                } else {
                    const nameA = x.Name.toUpperCase();
                    const nameB = y.Name.toUpperCase();

                    return nameA.localeCompare(nameB);
                }
            });
        },
        setIsRedirecting(state, action: PayloadAction<boolean>) {
            state.isRedirecting = action.payload;
        },
        resetGridSettings() {
            return { ...initialState };
        }
    }
});

export const { setProductReportData, applySorting, setIsRedirecting, resetGridSettings } = productReportSlice.actions;

export default productReportSlice.reducer;
