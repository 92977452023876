import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../../../redux/api/apiSlice';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import clientSelectionReducer from '../../../redux/reducers/clientSelectionReducer';
import ellipsisReducer from '../../../redux/reducers/ellipsisSlice';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import portalSolutionsReducers from '../../../redux/reducers/portalSolutionsSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import viewImagesSlice from '../../../redux/reducers/viewImagesSlice';
import dateFilterReducer from './reducers/dateFilterSlice';
import dateRangeReducer from './reducers/dateRangeSlice';
import docTypeReducer from './reducers/docTypeSlice';
import imagingFiltersReducer from './reducers/imagingFiltersSlice';
import imagingGridPreferencesReducer from './reducers/imagingGridPreferenceSlice';
import imagingGridSettingsReducer from './reducers/imagingGridSettingsSlice';
import imagingReducer from './reducers/imagingSlice';

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,
        viewImages: viewImagesSlice,
        ellipsis: ellipsisReducer,

        // page specific slices
        imaging: imagingReducer,
        imagingGridPreferences: imagingGridPreferencesReducer,
        dateRange: dateRangeReducer,
        imagingGridSettings: imagingGridSettingsReducer,
        imagingFilters: imagingFiltersReducer,
        dateFilter: dateFilterReducer,
        docFilter: docTypeReducer
    },
    middleware: (gDM) => gDM().concat(apiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
