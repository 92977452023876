import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Badge from '../../../common/features/Badge';
import CloseButton from '../../../common/features/CloseButton';
import { createDateForAlert } from '../../../common/functions/parser';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { usePortalSelector } from '../../../redux/hooks';
import { AgencyList } from '../../constants/constants-portal';
import Button from '../Button';
import ToastWrapper from '../Wrappers/ToastWrapper';

interface AlertToastCardProps {
    type: string;
    date: string;
    title?: string;
    description?: string;
    entryNumber?: string;
    agencyKey?: string;
    customsInfoID?: number;
    timeout?: number;
}

const AlertToastCard: React.FC<AlertToastCardProps> = ({ type, title, date, description = '', entryNumber, agencyKey, customsInfoID }) => {
    const { languageSelected } = usePortalSelector((state) => state.language);
    const { user } = usePortalSelector((state) => state.hydration);

    const [showAlert, setShowAlert] = useState(true);
    const translate = useTranslationHydration();
    const history = useHistory();

    const timeout = user?.PortalTimeoutLong || 8000;

    // Show card on first load and hide it after 8 seconds
    useEffect(() => {
        localStorage.setItem('alertsToastTime', new Date().toISOString());
    }, []);

    // Close alert after clicking the "close" button
    const closeAlert = () => {
        setShowAlert(false);
    };

    const handleViewEntry = () => {
        history.push(`/Clearances/${customsInfoID}`);
    };

    return (
        <>
            {showAlert && (
                <ToastWrapper type={type.toUpperCase() === 'NOTICE' ? 'notice-alert' : 'on-hold-alert'} timeout={timeout}>
                    <div className='toast-body alert-toasts d-flex flex-column align-items-start p-4'>
                        <div
                            style={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            {/* Badge: Shipment on hold */}
                            {type.toUpperCase() === 'ON_HOLD' && (
                                <Badge className='mb-2' type='danger'>
                                    <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />
                                    {translate('ShipmentOnHold_Label')}
                                </Badge>
                            )}
                            {/* Badge: In progress */}
                            {type.toUpperCase() === 'IN_PROGRESS' && (
                                <Badge className='mb-2' type='info'>
                                    {translate('StatusInProgress_Label')}
                                </Badge>
                            )}
                            {/*Badge: Livingston notice */}
                            {type.toUpperCase() === 'NOTICE' && (
                                <Badge className='mb-2' type='neutral'>
                                    {translate('LivingstonNoticeTitle_Label')}
                                </Badge>
                            )}
                            {/* Date and Close button */}
                            <div style={{ display: 'flex', marginLeft: '1rem', alignItems: 'start' }}>
                                <p style={{ color: '#3c5f88', opacity: 0.6, marginBottom: '0.5rem' }}>
                                    {createDateForAlert(date, languageSelected?.locale)}
                                </p>
                                <CloseButton
                                    type='toast'
                                    style={{ position: 'relative', top: '0.5rem', right: 0, marginLeft: '0.5rem' }}
                                    onClick={closeAlert}
                                />
                            </div>
                        </div>
                        {/* Title */}
                        <p className='toast-title' style={{ width: '100%', marginBottom: '0.5rem' }}>
                            <strong>
                                {type.toUpperCase() === 'ON_HOLD' &&
                                    translate(
                                        'AlertsOnHold_Label',
                                        entryNumber,
                                        translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                                    )}
                                {type.toUpperCase() === 'IN_PROGRESS' &&
                                    translate(
                                        'AlertsInProgress_Label',
                                        entryNumber,
                                        translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                                    )}
                                {type.toUpperCase() === 'NOTICE' && title}
                            </strong>
                        </p>
                        {/* Description */}
                        {type.toUpperCase() === 'ON_HOLD' && <p className='toast-desc mb-0'>{translate('HoldAlert_Label')}</p>}
                        {type.toUpperCase() === 'IN_PROGRESS' && (
                            <p className='toast-desc mb-0'>{translate('AlertsReviewMessage_Label')}</p>
                        )}
                        {type.toUpperCase() === 'NOTICE' && (
                            <p
                                className='toast-desc mb-0'
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description.replace(/&nbsp;/g, ' ')) }}
                            ></p>
                        )}
                        {/* View entry link */}
                        {(type.toUpperCase() === 'ON_HOLD' || type.toUpperCase() === 'IN_PROGRESS') && (
                            <Button variant='tertiary-blue' className='mt-3' onClick={handleViewEntry}>
                                {translate('ViewEntry_Label')}
                            </Button>
                        )}
                    </div>
                </ToastWrapper>
            )}
        </>
    );
};

export default AlertToastCard;
