import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../axios-portal';
import { API_CASS_PATH } from '../../../../common/constants/constants-portal';
import { addToDownloadLobby } from '../../../../redux/reducers/alertsSlice';
import { EntryClientPair } from '../../../clearances/common/models/CADDetails';
import { CASSDataModel } from '../../common/models/completeEntryDataModels';
import { DOWNLOAD_ENTRIES, GET_CASS_INDEX, SUBMIT_SEARCH } from './actionTypes';

export const GetCASSIndex = createAsyncThunk(GET_CASS_INDEX, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<CASSDataModel>(`${API_CASS_PATH}/Index`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetCASSData = createAsyncThunk(SUBMIT_SEARCH, async (payload: {}, { rejectWithValue }) => {
    try {
        const response = await axios.post<CASSDataModel>(`${API_CASS_PATH}/SubmitShipmentCASSSearch`, payload);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error === 'Request is canceled' ? error : error.response.status);
    }
});

export const DownloadCASSShipment = createAsyncThunk(
    DOWNLOAD_ENTRIES,
    async (payload: { entries: EntryClientPair[]; returnAllFields: boolean }, { dispatch }) => {
        try {
            const response = await axios.post<any>(`${API_CASS_PATH}/DownloadCASSShipment`, {
                EntryClientPairs: payload.entries ?? [],
                ReturnAllFields: payload.returnAllFields
            });

            const parsed = JSON.parse(response.data);

            // Check if response has download lobby
            if (parsed?.ImageUrl.includes('DownloadLobby')) {
                const match = parsed.ImageUrl.match(/packageId=(\d+)/);

                if (match) {
                    const packageId = match[1];
                    dispatch(addToDownloadLobby(packageId));
                }
            }

            return response.data;
        } catch (error: any) {
            return error.response.status;
        }
    }
);
