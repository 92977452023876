import { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import CloseButton from '../../../common/features/CloseButton';
import Spinner from '../../../common/features/Spinner';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { useActiveNoticesQuery } from '../../../redux/api/noticesApi';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import { removeUnreadLobbyItems } from '../../../redux/reducers/alertsSlice';
import { useDebounce } from '../../hooks/useDebounce';
import AlertDrawerCard from './AlertDrawerCard';
import DownloadLobbyDrawerCard from './DownloadLobbyDrawerCard';

const ENTER_TIMEOUT = 50;
const EXIT_TIMEOUT = 150;

interface AlertsDrawerProps {
    onToggleAlerts: () => void;
}

const AlertsDrawer = memo(function AlertsDrawer({ onToggleAlerts }: AlertsDrawerProps) {
    const { alerts, downloadLobby } = usePortalSelector((state) => state.alerts);
    const dispatch = usePortalDispatch();

    const { isFetching: noticesIsFetching, error: noticesError } = useActiveNoticesQuery();

    const [showAnimation, setShowAnimation] = useState(false);
    const translate = useTranslationHydration();
    const parentContainer = document && document.querySelector('#portalDrawer');

    const debounceEnter = useDebounce(ENTER_TIMEOUT);
    const debounceExit = useDebounce(EXIT_TIMEOUT);

    useEffect(() => {
        debounceEnter(() => {
            setShowAnimation(true);
        });
    }, [debounceEnter]);

    useEffect(() => {
        // Remove all unread download lobby items
        dispatch(removeUnreadLobbyItems());
    }, [dispatch]);

    const handleExit = () => {
        setShowAnimation(false);

        debounceExit(() => {
            onToggleAlerts();
        });
    };

    return (
        <>
            {alerts &&
                parentContainer &&
                ReactDOM.createPortal(
                    <>
                        <aside className={`offcanvas alerts-drawer ${showAnimation ? 'show' : ''}`}>
                            {/* Heading */}
                            <div
                                className='alerts-drawer-heading sticky-top bg-white border-bottom p-4'
                                style={{ display: 'flex', alignItems: 'start' }}
                            >
                                <h3 className='mb-0'>{translate('AlertsTitle_Label')}</h3>
                                <CloseButton onClick={handleExit} style={{ position: 'relative', top: '0.5rem', right: 0 }} />
                            </div>
                            {/* Alert cards */}
                            {/* Status: pending (waiting for the notices to load) */}
                            {noticesIsFetching && (
                                <div className='w-100 h-50 d-flex align-items-center justify-content-center'>
                                    <Spinner />
                                </div>
                            )}
                            {/* Status: fulfilled */}
                            {!noticesIsFetching && !noticesError && (
                                <div className='alert-cards p-4' style={{ minWidth: '0px' }}>
                                    {/* Download lobby */}
                                    {downloadLobby.map((item) => (
                                        <DownloadLobbyDrawerCard key={item.packageId} {...item} handleClose={handleExit} />
                                    ))}
                                    {/* Status alerts */}
                                    {alerts.statusAlerts.map((alert) => (
                                        <AlertDrawerCard
                                            key={alert.id}
                                            type={alert.type}
                                            date={alert.date}
                                            entryNumber={alert.entryNumber}
                                            agencyKey={alert.agencyKey}
                                            customsInfoID={alert.customsInfoID}
                                            handleClose={handleExit}
                                        />
                                    ))}
                                    {/* Notices */}
                                    {alerts.notices.map((alert) => (
                                        <AlertDrawerCard
                                            key={alert.id}
                                            type={alert.type}
                                            date={alert.date}
                                            title={alert.title}
                                            description={alert.description}
                                        />
                                    ))}
                                    {/* No new alerts message */}
                                    {!alerts.statusAlerts.length && !alerts!.notices.length && !downloadLobby.length && (
                                        <p>{translate('AlertsNoNew_Label')}</p>
                                    )}
                                </div>
                            )}
                        </aside>
                        <div className={`screen-overlay ${showAnimation ? 'show' : ''}`} onClick={handleExit}></div>
                    </>,
                    parentContainer
                )}
        </>
    );
});

export default AlertsDrawer;
