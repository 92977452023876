import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useHistory } from 'react-router-dom';
import useTranslation from '../../../../common/hooks/useTranslation';

interface FormattedGridCellProps extends GridCellProps {
    onClickFunction?(): void;
}
const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    onClickFunction,
    field = '',
    dataItem,
    selectionChange,
    className,
    style,
    ...rest
}) => {
    let cellContent;
    const translate = useTranslation();
    const history = useHistory();

    switch (field) {
        case 'selected':
            cellContent = (
                <Checkbox
                    name={dataItem.TransactionNumber}
                    disabled={false}
                    // value={}
                    // onChange={checkboxChange}
                />
            );
            break;
        case 'Number':
            cellContent = dataItem['Number'];
            break;
        case 'Name':
            cellContent = dataItem['Name'];
            break;
        case 'Download':
            cellContent = (
                <div className='d-flex justify-content-center'>
                    <FontAwesomeIcon icon={faFileDownload} size='lg' />
                </div>
            );
            break;
    }
    return (
        <td
            style={{
                ...style
            }}
        >
            {cellContent}
        </td>
    );
};

export { FormattedGridCell };
